import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Input, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

declare let window: any;

@Component({
    selector: 'app-chat-live',
    template: '',
})
export class ChatLiveComponent {
    private destroy$ = new Subject<void>();

    @Input() chatLive: Subject<void>;

    private readonly platformId = inject(PLATFORM_ID);

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {
        // Si el Subject no es proporcionado por el componente padre, inicializa uno nuevo.
        this.chatLive = new Subject<void>();
    }

    ngAfterViewInit() {
        this.loadChatScript();
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe((event: NavigationEnd) => {
                if (event.urlAfterRedirects === '/dashboard/user-profile') {
                    this.showChat();
                } else {
                    this.hideChatWidget();
                }
            });

        this.listenChatLive();
    }

    ngOnDestroy() {
        this.hideChatWidget();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private listenChatLive() {
        // Escucha el Subject para mostrar el chat cuando se emita un evento desde el componente padre.
        this.chatLive.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.showChat();
        });
    }

    hideChatWidget() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.Tawk_API) {
                window.Tawk_API.hideWidget();
            }
        }
    }

    private showChat() {
        if (window.Tawk_API) {
            window.Tawk_API.showWidget();
            window.Tawk_API.maximize();
            this.cdr.detectChanges(); // informa a Angular para detectar cambios
        }
    }

    private loadChatScript() {
        if (document.getElementById('tawk-script')) return;

        const s1 = document.createElement('script');
        s1.id = 'tawk-script';
        const s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/649b0fbf94cf5d49dc6029ab/1h3urb142';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');

        if (!s0.parentNode) return;

        s0.parentNode.insertBefore(s1, s0);
    }
}
