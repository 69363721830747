import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '@modules/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { ChatLiveComponent } from '@shared/components/chat-live/chat-live.component';
import { GoBackComponent } from '@shared/components/go-back/go-back.component';

@NgModule({
    declarations: [GoBackComponent, ChatLiveComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule, PrimeNGModule],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, GoBackComponent, ChatLiveComponent, TranslateModule, PrimeNGModule],
    providers: [SweetAlertsService],
})
export class SharedModule {}
