import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-go-back',
    templateUrl: './go-back.component.html',
    styleUrls: ['./go-back.component.scss'],
})
export class GoBackComponent {
    @Input() color: string = 'primary';
    @Input() url: string = '';
    @Input() noURL: boolean = false;

    private router: Router;

    constructor(router: Router) {
        this.router = router;
    }

    goBack(): void {
        if (!this.noURL) {
            if (this.url) {
                this.router.navigate([this.url]);
                return;
            }
            window.history.back();
        }
    }
}
